import React from 'react'

const SocialNetwork = () => {
  const animation = () => {
    // console.log('test affichage')
    let navLinks = document.querySelectorAll('.social-network a')

    navLinks.forEach((link) => {
      link.addEventListener('mouseover', (e) => {
        let attrX = e.offsetX - 20
        let attrY = e.offsetY - 13

        link.style.transform = `translate(${attrX}px, ${attrY}px)`
      })
      link.addEventListener('mouseleave', () => {
        link.style.transform = ''
      })
    })
  }

  return (
    <nav className='social-network'>
      <ul className='content'>
        <a
          href='https://www.facebook.com/germain.thibault.334'
          target='_blank'
          rel='noopener noreferrer'
          className='hover'
          onMouseOver={animation}
        >
          <li>
            <i className='fab fa-facebook-f'></i>
          </li>
        </a>
        <a
          href='https://twitter.com/germbault'
          target='_blank'
          rel='noopener noreferrer'
          className='hover'
          onMouseOver={animation}
        >
          <li>
            <i className='fab fa-twitter'></i>
          </li>
        </a>
        <a
          href='https://www.linkedin.com/in/germain-thibault-318b971ba/'
          target='_blank'
          rel='noopener noreferrer'
          className='hover'
          onMouseOver={animation}
        >
          <li>
            <i class='fab fa-linkedin-in'></i>
          </li>
        </a>
      </ul>
    </nav>
  )
}

export default SocialNetwork
