export const skills = [
  { id: 1, img: '../../media/select.svg', skill: 'Anglais' },
  { id: 2, img: '../../media/select.svg', skill: 'GitHub' },
  { id: 3, img: '../../media/select.svg', skill: 'Méthode agile' },
  { id: 4, img: '../../media/select.svg', skill: 'Figma' },
  { id: 5, img: '../../media/select.svg', skill: 'UI/UX Design' },
  { id: 6, img: '../../media/select.svg', skill: 'Photoshop' },
  { id: 7, img: '../../media/select.svg', skill: 'Illustrator' },
  { id: 8, img: '../../media/select.svg', skill: 'Bitbucket' },
  { id: 9, img: '../../media/select.svg', skill: 'Jira' },
  { id: 10, img: '../../media/select.svg', skill: 'Chromatic' },
  { id: 11, img: '../../media/select.svg', skill: 'WampServer' },
  { id: 12, img: '../../media/select.svg', skill: 'TypeScript' },
  { id: 13, img: '../../media/select.svg', skill: 'Vs.Code' },
  { id: 15, img: '../../media/select.svg', skill: 'Window' },
  { id: 16, img: '../../media/select.svg', skill: 'Linux' },
  { id: 17, img: '../../media/select.svg', skill: 'Gestion déquipe' },
  {
    id: 18,
    img: '../../media/select.svg',
    skill: 'Compétence de supervision'
  },
  {
    id: 19,
    img: '../../media/select.svg',
    skill: 'Page builder (Elementor)'
  },
  {
    id: 20,
    img: '../../media/select.svg',
    skill: 'Page builder (Divi)'
  },
  {
    id: 21,
    img: '../../media/select.svg',
    skill: 'Builder io'
  },
  {
    id: 22,
    img: '../../media/select.svg',
    skill: 'W3C'
  },
]
