import React, { Component } from 'react'
import ProgressBar from './ProgressBar'
import { languages, cms } from '../../data/languagesData'

class Language extends Component {
  state = {
    languages: languages,
    cms
  }

  render() {
    let { languages, cms } = this.state

    return (
      <section className='languages-container'>
        <ProgressBar
          array={languages}
          className='languages-display'
          title='Langages & FrameWorks'
        />
        <ProgressBar array={cms} className='cms-display' title='Cms' />
      </section>
    )
  }
}

export default Language
