import React from 'react'
import DynamicText from '../components/DynamicText'
import Footer from '../components/Footer'
import Header from '../components/Header'
import SwitchMode from '../components/SwitchMode'
import { NavLink } from 'react-router-dom'

const Home = () => {
  return (
    <div className='bg-home home'>
      <Header />
      <SwitchMode />
      <main className='container home-main'>
        <section className='main-content'>
          <h1>GERMAIN THIBAULT</h1>
          <h2>
            <DynamicText />
          </h2>
          <div className='wrapper-link'>
            <NavLink to='/portfolio'>
              <div className='portfolio'>Voir mon portfolio +</div>
            </NavLink>
            <NavLink to='../media/carriculum vitea.pdf' target='_blank'>
              <div className='pdf'>Voir mon CV +</div>
            </NavLink>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  )
}

export default Home
