import React, { useState, useEffect } from 'react'

const SwitchMode = () => {
  const [darkMode, setDarkMode] = useState(false)

  useEffect(() => {
    if (darkMode) {
      document.body.classList.add('dark')
    } else {
      document.body.classList.remove('dark')
    }
  }, [darkMode])

  const toggleDarkMode = () => setDarkMode(!darkMode)

  return (
    <div className=' container toggle'>
      <input
        id='switch-mode'
        onChange={toggleDarkMode}
        type='checkbox'
        className='checkbox'
      />
      <label for='switch-mode' className='label'>
        <i class='fas fa-moon'></i>
        <i class='fas fa-sun'></i>
        <div className='ball'></div>
      </label>
    </div>
  )
}

export default SwitchMode
