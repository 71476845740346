import React from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'

const InfoContact = () => {
  return (
    <section className='width50 info-form'>
      <section className='address'>
        <div className='img-content'>
          <img className='' src={'../../media/home.svg'} alt='icon-home' />
        </div>
        <div className='info-content'>
          <h4>Adresse</h4>
          <p>
            175 chemin des pionniers, Ouest
            <br />
            Cap-st-ignace,Québec,Canada <br /> G0R 1H0
          </p>
        </div>
      </section>
      <section className='phone'>
        <div className='img-content'>
          <img className='' src={'../../media/phone.svg'} alt='icon-phone' />
        </div>
        <div className='info-content'>
          <h4>Téléphone</h4>
          <CopyToClipboard text='418-246-2228' className='hover'>
            <p
              style={{ cursor: 'pointer' }}
              onClick={() => {
                alert('Téléphone copié !')
              }}
            >
              418-246-2228
            </p>
          </CopyToClipboard>
        </div>
      </section>
      <section className='email'>
        <div className='img-content'>
          <img className='' src={'../../media/email.svg'} alt='icon-email' />
        </div>
        <div className='info-content'>
          <h4>Courriel</h4>
          <CopyToClipboard text='germbault@globetrotter.net' className='hover'>
            <p
              style={{ cursor: 'pointer' }}
              onClick={() => {
                alert('Courriel copié !')
              }}
            >
              germbault@globetrotter.net
            </p>
          </CopyToClipboard>
        </div>
      </section>
    </section>
  )
}

export default InfoContact
