import React from 'react'
import { NavLink } from 'react-router-dom'

const Navigation = () => {
  return (
    <nav className='navigation'>
      <ul>
        <NavLink to='/' exact className='hover' activeClassName='nav-active'>
          <img
            className='icon-home'
            src={'../../media/homeNavigation.svg'}
            alt='home'
          />
          <li className='current'>Accueil</li>
        </NavLink>

        <NavLink
          to='/competences'
          exact
          className='hover'
          activeClassName='nav-active'
        >
          <img
            className='icon-skill'
            src={'../../media/candidate.svg'}
            alt='skill'
          />
          <li>Compétences</li>
        </NavLink>

        <NavLink
          to='/portfolio'
          exact
          className='hover'
          activeClassName='nav-active'
        >
          <img
            className='icon-portfolio'
            src={'../../media/portfolio.svg'}
            alt='portfolio'
          />
          <li>Portfolio</li>
        </NavLink>

        <NavLink
          to='/contact'
          exact
          className='hover'
          activeClassName='nav-active'
        >
          <img
            className='icon-contact'
            src={'../../media/contact.svg'}
            alt='contact'
          />
          <li>Contact</li>
        </NavLink>
      </ul>
    </nav>
  )
}

export default Navigation
