export const projectsData = [
  {
    id: 1,
    title: 'Engin-sol',
    languages: ['[ HTML5,', 'Php,', 'CSS3 ]'],
    infos: 'Projet de production 1',
    img: './media/projet-1.png',
    link: 'http://engine-sol.portfolio-germain-thibault.com/',
    source: 'Site web'
  },
  {
    id: 2,
    title: 'Vertlette',
    languages: ['[ HTML5,', 'CSS3,', 'Sass,', 'Php,', 'Wordpress ]'],
    infos: 'Travaux pratique en intégration 2',
    img: './media/projet-2.png',
    link: 'http://vertlette.portfolio-germain-thibault.com/',
    source: 'Site web'
  },
  {
    id: 3,
    title: 'Imagine-code',
    languages: ['[ React,', 'HTML5,', 'CSS3,', 'TypeScript,', 'Node.js ]'],
    infos: 'Travaux pratique en programmation 2',
    img: './media/projet-3.png',
    link: 'https://github.com/germbault451/imagine-code.git',
    source: 'Code source'
  },
  {
    id: 4,
    title: 'Effix',
    languages: ['[ React,', 'HTML5,', 'CSS3,', 'Sass,', 'Php,', 'Wordpress ]'],
    infos: 'Projet de production 2',
    img: './media/projet-4.png',
    link: 'https://bitbucket.org/germbault/effix-cms/src/master/',
    source: 'Code source'
  },
  {
    id: 5,
    title: 'Portfolio',
    languages: ['[ React,', 'HTML5,', 'CSS3,', 'Sass ]'],
    infos: 'Portfolio sur lequel vous êtes',
    img: './media/projet-5.png',
    link: 'https://portfolio-germain-thibault.com',
    source: 'Site web'
  }
]
