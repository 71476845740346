import React from 'react'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'

import Home from './pages/Home'
import Knowledges from './pages/Knowledges'
import Contact from './pages/Contact'
import Portfolio from './pages/Portfolio'

const App = () => {
  return (
    <>
      <BrowserRouter>
        <Switch>
          <Route path='/' exact component={Home} />
          <Route path='/competences' component={Knowledges} />
          <Route path='/portfolio' component={Portfolio} />
          <Route path='/contact' component={Contact} />
          <Redirect to='/' />
        </Switch>
      </BrowserRouter>
    </>
  )
}

export default App
