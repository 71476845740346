import React from 'react'
import Footer from '../components/Footer'
import Header from '../components/Header'
import Project from '../components/Project'

const Portfolio = () => {
  return (
    <React.Fragment>
      <Header />
      <main className='container'>
        <section className='title-project'>
          <h1>Portfolio - Réalisations</h1>
        </section>
        <section className='main-project'>
          <Project projectNumber={0} />

          <Project projectNumber={1} />

          <Project projectNumber={2} />

          <Project projectNumber={3} />

          <Project projectNumber={4} />
        </section>
      </main>
      <Footer />
    </React.Fragment>
  )
}

export default Portfolio
