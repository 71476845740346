import React from 'react'
import SocialNetwork from '../components/SocialNetwork'

const Footer = () => {
  return (
    <footer className='container'>
      <SocialNetwork />
    </footer>
  )
}

export default Footer
