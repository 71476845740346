import React from 'react'
import Footer from '../components/Footer'
import FormContact from '../components/FormContact'
import Header from '../components/Header'
import InfoContact from '../components/InfoContact'

const Contact = () => {
  return (
    <React.Fragment>
      <Header />
      <main className='container contact-main'>
        <section className='title-project'>
          <h1>Contactez-moi</h1>
        </section>
        <section className='main-contact'>
          <FormContact />
          <InfoContact />
        </section>
      </main>
      <Footer />
    </React.Fragment>
  )
}

export default Contact
