export const languages = [
  { id: 1, value: 'Html5/Css3', xp: 1.8 },
  { id: 2, value: 'Javascrip/JQuery', xp: 1.5 },
  { id: 3, value: 'React', xp: 1.4 },
  { id: 4, value: 'Sass', xp: 1.8 },
  { id: 5, value: 'Php', xp: 1.2 },
  { id: 6, value: 'SQL', xp: 1.13 },
  { id: 7, value: 'MySql', xp: 1.13 },
  { id: 8, value: 'Node.js', xp: 1.13 },
  { id: 9, value: 'PhpMyAdmin', xp: 1.8 }
]
export const cms = [
  { id: 1, value: 'Wordpress', xp: 1.7 },
  { id: 2, value: 'Magento "Survol du CMS"', xp: 1 }
]
