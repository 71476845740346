import React from 'react'
import Navigation from '../components/Navigation'
import { NavLink } from 'react-router-dom'

const Header = () => {
  return (
    <header className='bg-header'>
      <section className='container flex-content'>
        <NavLink to='/'>
          <img className='logo' src={'../../media/logo.png'} alt='logo' />
        </NavLink>
        <Navigation />
      </section>
    </header>
  )
}

export default Header
