import React, { useState } from 'react'
import { projectsData } from '../data/projectsData'

const Project = (props) => {
  const [currentProject] = useState(projectsData)
  const project = currentProject[props.projectNumber]

  return (
    <article className='article-content'>
      <a
        href={project.link}
        target='_blank'
        rel='noopener noreferrer'
        className='hover'
      >
        <section className='img-content'>
          <div className='img-container hover'>
            <span>
              <h3>
                <img
                  className='icon-link-color2'
                  src={'../../media/link.svg'}
                  alt='icon-link'
                />
                {project.title}
              </h3>
              <p>{project.infos}</p>
            </span>
            <img src={project.img} alt={project.title} />
          </div>
        </section>
      </a>
      <aside className='aside-content'>
        <div>
          <h1>{project.title}</h1>

          <ul className=''>
            {project.languages.map((item) => {
              return <li key={item}>{item}</li>
            })}
          </ul>
          <p>{project.infos}</p>
        </div>
        <a
          href={project.link}
          target='_blank'
          rel='noopener noreferrer'
          className='hover'
        >
          <p className='source'>
            <img
              className='icon-link-color1'
              src={'../../media/link.svg'}
              alt='icon-link'
            />
            {project.source}
          </p>
        </a>
      </aside>
    </article>
  )
}

export default Project
