import React from 'react'
import Footer from '../components/Footer'
import Header from '../components/Header'
import Language from '../components/knowledges/Language'
import OtherSkills from '../components/knowledges/OtherSkills'

const Knowledges = () => {
  return (
    <React.Fragment>
      <Header />
      <main className='container knowledge-main'>
        <section className='title-project'>
          <h1>Mes Compétences</h1>
        </section>
        <section className='main-knowledge'>
          <Language />
          <OtherSkills />
        </section>
      </main>
      <Footer />
    </React.Fragment>
  )
}

export default Knowledges
