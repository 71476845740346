import React from 'react'
import { skills } from '../../data/otherSkillsData'

const OtherSkills = () => {
  return (
    <section className='skill-container'>
      <h3>Autres Compétences</h3>
      <div className='list'>
        <ul>
          {skills.map((item) => {
            return (
              <li key={item.id}>
                <img
                  className='icon-check-color1'
                  src={item.img}
                  alt='icon-check'
                />
                <span>{item.skill}</span>
              </li>
            )
          })}
        </ul>
      </div>
    </section>
  )
}

export default OtherSkills
