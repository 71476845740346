import React from 'react'

const ProgressBar = (props) => {
  return (
    <section className={props.className}>
      <h3>{props.title}</h3>
      <div className='years'>
        <span>Années d'expérience</span>
        <span>2 an</span>
        <span>4 ans</span>
      </div>

      <div>
        {props.array.map((item) => {
          let xpYears = 2.3
          let progressBar = (item.xp / xpYears) * 100 + '%'

          return (
            <div key={item.id} className='languages-list'>
              <li>{item.value}</li>
              <div
                className='progress-bar'
                style={{ width: progressBar }}
              ></div>
            </div>
          )
        })}
      </div>
    </section>
  )
}

export default ProgressBar
